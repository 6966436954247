<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">学期:</div>
            <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container"
                       @change="onSemester()">
                <el-option
                    v-for="item in this.$store.state.chinese.semester.list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">出版社名称:</div>
            <el-select size="small" v-model="bookId" placeholder="请选择" class="select_container" @change="onBook()">
                <el-option
                    v-for="item in this.$store.state.chinese.bookName.list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">单元名称:</div>
            <el-select size="small" v-model="unitId" placeholder="请选择" class="select_container" @change="onunit()">
                <el-option
                    v-for="item in this.$store.state.chinese.unitName.list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">章节名称:</div>
            <el-select size="small" v-model="chapter2Id" placeholder="请选择" class="select_container">
                <el-option
                    v-for="item in this.$store.state.chinese.chapterName.list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div class="manage_container">
            <div class="manage">字词库中选词:</div>
            <el-autocomplete
                class="input-width"
                v-model="wordName"
                :fetch-suggestions="queryWord"
                placeholder="请输入单词搜索单词库"
                :trigger-on-focus="false"
                @change="onWordChange"
                @select="onWordSelect"
            ></el-autocomplete>
        </div>

        <div v-if="subjectId===1">
            <div class="manage_container">
                <div class="manage">字词:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.name"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">拼音:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.pinyin"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">音频文件:</div>
                <el-upload
                    v-loading="soundLoading"
                    class="sound-upload"
                    action=""
                    drag
                    :show-file-list="false"
                    :auto-upload="true"
                    :http-request="soundUpload">
                    <div v-if="word.sound" class="sound">{{word.sound}}</div>
                    <i v-else class="el-icon-upload image-upload-icon"></i>
                </el-upload>
            </div>
            <div class="manage_container">
                <div class="manage">笔顺图片:</div>
                <el-upload
                    v-loading="bishunLoading"
                    class="image-upload"
                    list-type="picture-card"
                    :limit="4"
                    action=""
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :auto-upload="true"
                    :http-request="imageUpload">
                    <i class="el-icon-plus image-upload-icon"></i>
                </el-upload>
            </div>
        </div>

        <div v-if="subjectId===2">
            <div class="manage_container">
                <div class="manage">英文:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.yingwen"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">汉语:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.name"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">词性:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.bushou"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">美式音标:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.symbol1"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">美式发音:</div>
                <audio :src="getBookUrl(word.sound1)" alt="" v-if="word.sound1" controls style="width: 260px"></audio>
                <el-upload
                    v-loading="sound1Loading"
                    class="sound-upload"
                    action=""
                    drag
                    :show-file-list="false"
                    :auto-upload="true"
                    :http-request="sound1Upload">
                    <div v-if="word.sound1" class="sound">{{word.sound1}}</div>
                    <i v-else class="el-icon-upload image-upload-icon"></i>
                </el-upload>
            </div>
            <div class="manage_container">
                <div class="manage">英式音标:</div>
                <el-input class="manage_input" type="text" size="small" v-model="word.symbol2"></el-input>
            </div>
            <div class="manage_container">
                <div class="manage">英式发音:</div>
                <audio :src="getBookUrl(word.sound2)" alt="" v-if="word.sound2" controls style="width: 260px"></audio>
                <el-upload
                    v-loading="sound2Loading"
                    class="sound-upload"
                    action=""
                    drag
                    :show-file-list="false"
                    :auto-upload="true"
                    :http-request="sound2Upload">
                    <div v-if="word.sound2" class="sound">{{word.sound2}}</div>
                    <i v-else class="el-icon-upload image-upload-icon"></i>
                </el-upload>
            </div>
            <div class="manage_container">
                <div class="manage">图片:</div>
                <el-upload
                    v-loading="word.bishunLoading"
                    class="image-upload"
                    list-type="picture-card"
                    :limit="4"
                    action=""
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :auto-upload="true"
                    :file-list="fileList"
                    :http-request="imageUpload">
                    <i class="el-icon-plus image-upload-icon"></i>
                </el-upload>
            </div>
            <div class="manage_container">
                <div class="manage">预览:</div>
                <div v-html="word.liju"></div>
            </div>
            <div class="manage_container">
                <div class="manage">例句:</div>
                <el-input class="manage_input" type="textarea" size="small" v-model="word.liju" rows="10"></el-input>
            </div>
        </div>


        <!--        // id;-->
        <!--        // yingwen; // 单词-->
        <!--        // symbol2; // 英式音标-->
        <!--        // symbol1; // 美式音标-->
        <!--        // sound2; // 英式发音-->
        <!--        // sound1; // 美式发音-->
        <!--        // bushou; // 词性-->
        <!--        // name;  // 词义-->
        <!--        // liju;  // 例句英语-->
        <!--        // liju2;  // 例句汉语-->
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="oncancel">取消</el-button>

    </div>

</template>

<script>
    import axios from '@/libs/axios'

    export default {
        name: "add",

        data() {
            return {

                /**
                 * 英文
                 private Integer id;
                 private Integer chapter2Id;
                 private String name; // 字名称，汉语
                 private String pinyin; // 拼音
                 private String liju; // 基本例句 <div>He cracked me on the head with a <span style="color:#1CB89C;">ruler</span>. <br/>他用尺子猛击我的头部。</div>
                 private String picurl; // 字对应的图片地址 ["images/ruler.jpg"]
                 private String bushou; // 词性
                 private String zaozifa;造字法
                 private String bishun; // 英文选项 []
                 private String bishunName; // 中文选项 []
                 private String yingwen; // 英文
                 private Short type; // 字词类型。1：字词、短语   2：句子
                 private String sound; // 汉语发音
                 private String sound1; // 美式发音
                 private String symbol1; // 美式音标
                 private String sound2; // 英式发音
                 private String symbol2; // 英式音标
                 private Integer wordId;
                 private Integer classify; // 语言类型  1：中文  2：英文
                 *
                 * 中文
                 private Integer id;
                 private Integer chapter2Id;
                 private String name; // 汉字
                 private String pinyin; // 拼音
                 private String picurl; // 字对应的图片地址  ["Chinese/images/chong.gif"]
                 private String sound; // 声音文件
                 */

                subjectId: undefined, // // 学科id 1:语文 2：英语
                semesterId: '', // 学期id
                bookId: '', // 出版社id
                unitName: '', // 单元名称
                chapterName: '', // 章节名称
                unitId: '', // 单元id
                chapter2Id: '', // 章节id
                word: {}, // 字词信息

                wordName: undefined, // 搜索词

                soundLoading: false,
                sound1Loading: false,
                sound2Loading: false,
                bishunLoading: false,
                fileLoading: false,
                fileList: [], // 笔顺图片列表  // {name: file.name, url: response.data.data.url}
            }
        },
        mounted() {
            this.subjectId = parseInt(this.$route.query.subjectId || "2");
            this.$store.dispatch("chinese/semesterList");
        },
        methods: {
            getBookUrl(url) {
                return url && axios.getBookUrl(url) || "";
            },

            // 搜索字词库
            queryWord(queryString, cb) {
                this.$axios.get("/v1/web/word/search", {params: {wordName: queryString}}).then((data) => {
                    const wordList = data.data.data.map(item => {
                        return {
                            value: item.yingwen,
                            id: item.id
                        }
                    });
                    cb(wordList);
                })
            },

            // 选中字词
            onWordSelect(word) {
                this.$axios.get("/v1/web/word/wordBase", {params: {id: word.id}}).then((data) => {
                    this.word = data.data.data;

                    //                String liju = "<div>\n" +
//                        "It is the biggest park in the <span style=\"color:#1CB89C;\">UK</span>.\n" +
//                        "<br/>\n" +
//                        "它是英国最大的公园。\n" +
//                        "</div>";

                    if (this.word.liju1 !== "--") {
                        let liju = "<div>\n" +
                            this.word.liju1.replace(this.word.yingwen, "<span style=\"color:#1CB89C;\">" + this.word.yingwen + "</span>") +
                            "<br/>\n" +
                            this.word.liju2 +
                            "</div>";

                        this.word.liju = liju; // 例句
                    }

                    if (this.word.picurl) {
                        this.fileList = JSON.parse(this.word.picurl).map(url => {
                            return {
                                url: url
                            }
                        })
                    }
                })
            },

            // 搜索词改变
            onWordChange(word) {
                console.log(word);
            },
            soundUpload(request) {
                this.soundLoading = true;
                axios.upload("/v1/web/file/book", request.file).then(response => {
                    this.word.sound = response.data.data.url;
                    this.soundLoading = false;
                }).catch((e) => {
                    console.log(e)
                });
            },
            sound1Upload(request) {
                this.sound1Loading = true;
                axios.upload("/v1/web/file/book", request.file).then(response => {
                    this.word.sound1 = response.data.data.url;
                    this.sound1Loading = false;
                }).catch((e) => {
                    console.log(e)
                });
            },
            sound2Upload(request) {
                this.sound2Loading = true;
                axios.upload("/v1/web/file/book", request.file).then(response => {
                    this.word.sound2 = response.data.data.url;
                    this.sound2Loading = false;
                }).catch((e) => {
                    console.log(e)
                });
            },


            // 图片上传
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 4 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            imageUpload(request) {
                this.bishunLoading = true;
                axios.upload("/v1/web/file/book", request.file).then(response => {
                    setTimeout(() => {
                        this.fileList.push({name: request.file.name, url: this.getBookUrl(response.data.data.url)})
                    }, 1000)
                    this.bishunLoading = false;
                }).catch((e) => {
                    console.log(e)
                });
            },
            handleRemove(file, fileList) {
                this.fileList = this.fileList.filter((item) => {
                    return item.name !== file.name;
                })
                console.log(this.fileList);
            },


            //确认添加
            onConfirm() {
                if (!this.semesterId) {
                    this.$message.warning('请选择学期');
                } else if (!this.bookId) {
                    this.$message.warning('请选择出版社');
                } else if (!this.unitId) {
                    this.$message.warning('请选择单元名称');
                } else if (!this.chapter2Id) {
                    this.$message.warning('请选择章节单元名称');
                } else {
                    this.$store.dispatch("chinese/addWord", {
                        ...this.word,
                        'chapter2Id': this.chapter2Id,
                        'picurl': JSON.stringify(this.fileList.map(item => item.url)),
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }
            },


            //取消添加
            oncancel() {
                this.$router.go(-1);
            },
            //月份改变
            onSemester() {
                this.$store.dispatch('chinese/bookNameList', {'semesterId': this.semesterId})
            },
            //出版社改变
            onBook() {
                this.$store.dispatch('chinese/unitNameList', {'bookId': this.bookId})
            },
            //单元改变
            onunit() {
                this.$store.dispatch('chinese/chapterNameList', {'unitId': this.unitId})
            }


        }
    }
</script>
<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

        .image-upload, .el-upload {
            cursor: pointer;
            display: inline-block;
            height: 150px;
            max-width: 630px;
            overflow: hidden;

            .image {
                width: 150px;
                height: 150px;
                display: block;
            }

            .sound {
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .image-upload-icon {
                font-size: 28px;
                color: #8c939d;
                width: 150px;
                height: 150px;
                line-height: 150px;
                text-align: center;
            }

            ::v-deep .el-upload-dragger {
                width: 148px;
                height: 148px;
                margin: 0;
            }

            ::v-deep .el-upload-dragger .el-icon-upload {
                margin: 0;
            }
        }

        .sound-upload, .el-upload {
            cursor: pointer;
            display: inline-block;
            height: 60px;
            max-width: 630px;
            overflow: hidden;

            .image {
                width: 150px;
                height: 60px;
                display: block;
            }

            .sound {
                width: 150px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .image-upload-icon {
                font-size: 28px;
                color: #8c939d;
                width: 150px;
                height: 60px;
                line-height: 60px;
                text-align: center;
            }

            ::v-deep .el-upload-dragger {
                width: 148px;
                height: 60px;
                margin: 0;
            }

            ::v-deep .el-upload-dragger .el-icon-upload {
                margin: 0;
            }
        }

        .manage_info {
            font-size: 14px;
            color: #606266;
        }

    }

</style>
